import AsyncStorage from "@react-native-async-storage/async-storage";
import { Fragment, useEffect, useState } from "react";
import { ActivityIndicator, Image, ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar, EmptyState } from "../components";
import SurveyCard from "../components/Survey/SurveyCard";
import { getSurveys } from "../services/survey";
import { EditIcon } from "../assets/icons";
import { checkRespondentEligible } from "../services/screening";
import md5 from "md5";
import { getProgramId } from "../services/programs";

const SurveyPage = ({ route }) => {
  const { params } = route;
  const [survey, setSurvey] = useState([]);
  const [memberId, setMemberId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState("");
  const [isQuick, setIsQuick] = useState(false);
  const [refId, setRefId] = useState(undefined);
  const [program, setProgram] = useState({});

  useEffect(() => {
    if (params.isQuick) {
      AsyncStorage.setItem("token", md5("quick"));
      setIsQuick(true);
    }

    async function getMember() {
      let theRefId = await AsyncStorage.getItem("refId");
      if (!theRefId) {
        theRefId = md5(new Date().getTime());
        AsyncStorage.setItem("refId", theRefId);
      }
      setRefId(theRefId);

      const memberId = await AsyncStorage.getItem("token");
      setMemberId(memberId);

      return memberId;
    }

    setTimeout(() => {
      getMember();
    }, 1000);

  }, []);

  useEffect(() => {
    if (!memberId) {
      return;
    }

    getProgramId(params.additionalContentId)
    .then((res) => {
      setProgram(res.data[0]);
    })

    getSurveys(params?.additionalContentId, memberId, (params?.isScreening === "1" ? "1" : "0") + (params.isQuick ?  `?ref_id=${refId}` : ''))
      .then((res) => {
        const surveyData = res.data;

        checkRespondentEligible(memberId, params?.additionalContentId)
        .then((res) => {
          if (res.data[0]?.roleName) {
            setRole(res.data[0].roleName);
          }

          setSurvey(surveyData);
        });
      })
      .finally(() => setIsLoading(false));
  }, [memberId, params, params?.refresh, refId]);

  return (
    <Fragment>
      <AppBar title={ isQuick ? "Program" : "Survey"} isBack={params?.backButton} />
      {isLoading && (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EEEAFF",
          }}
        >
          <ActivityIndicator color="#6724E2" />
        </View>
      )}
      {
        isQuick &&
        !isLoading &&
        <View style={styles.headerBox}>
            <Image
              source={{
                uri: `https://fammi.ly/uploads/products/images/${program?.productImage}`,
              }}
              style={styles.image}
            />
            <Text style={styles.title}>{program.productName}</Text>
            {program.productDescription && (
              <Text style={styles.desc}>
                {program.productDescription}
              </Text>
            )}
        </View>
      }
      { 
        !isLoading &&
        survey?.length > 0 &&
      (
        <ScrollView
          style={{
            flex: 1,
            padding: 16,
            backgroundColor: "#EEEAFF",
            gap: 16,
          }}
        >
          {survey.map((item) => (
            <SurveyCard key={item.quizId} data={item} additionalContentId={params?.additionalContentId} isScreening={params?.isScreening} role={role} isQuick={isQuick} />
          ))}
          <View style={{height: 16}}>
            <Text>
              &nbsp;
            </Text>
          </View>
        </ScrollView>
      )}
      {
        !isLoading &&
        survey?.length === 0 &&
        <ScrollView contentContainerStyle={styles.container}>
                <EmptyState
                    Icon={EditIcon}
                    title={"Kamu Belum Punya "+(params?.isScreening === "1" ? "Screening" : "Survey")}
                    desc={"Saat ini belum ada "+(params?.isScreening === "1" ? "screening" : "survey")+" diberikan di dalam program"}
                />
            </ScrollView>

      }
    </Fragment>
  );
};
const styles = StyleSheet.create({
  container: {
      flex: 1,
      padding: 16,
      backgroundColor: "#EEEAFF",
  },
  headerBox: {
    backgroundColor: "#fff",
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  image: {
    width: "100%",
    height: 61,
    borderRadius: 10,
    resizeMode: "cover",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    marginTop: 16,
    marginBottom: 10,
  },
  desc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    lineHeight: 16,
  },
})


export default SurveyPage;
