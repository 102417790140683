import { APP_VERSION, APP_SPECIAL_PACKAGE } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  Image,
  Linking,
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  StatusBar,
  View,
} from "react-native";
import {
  AgendaCard,
  BottomNavigation,
  EbookCard,
  HeaderJoin,
  HomeHeader,
  HomeMenu,
  InvoiceCard,
  ListProgram,
  MenuCard,
  ModalAddProgram,
  PopupFactory,
  PromoCard,
  SectionContent,
  ServiceCard,
  Snackbar,
  TodayExpertCard,
} from "../components";
import { MoreMenuModal } from "../components/HomeMenu";
import JoinProgramCard from "../components/JoinProgramCard";
import ModalKode from "../components/ModalKode";
import messaging from "@react-native-firebase/messaging";
import { usePopup } from "../components/PopupFactory";
import { getMemberAgenda, getSpesificAgenda } from "../services/agenda";
import { getContents } from "../services/contents";
import { getMemberCredits } from "../services/member_credits";
import { checkUpdate, getPackages } from "../services/packages";
import {
  getListDiscussionsById,
  getProgram,
  getProgramId,
  getRecommendation,
  resubscribe,
  setProgram,
} from "../services/programs";
import { getTransactionByMember } from "../services/transactions";
import "../translation";
import moment from "moment";
import CardVideo from "../components/CardVideo";
import CardTest from "../components/CardTest";
import ChoosenProgram from "../components/ChoosenProgram";
import { getSurveys } from "../services/survey";
import { getConsultants, getTodayConsultants } from "../services/consultants";
import { getMaterials } from "../services/materials";

function Home({ route }) {
  const [isMoreMenuVisible, setIsMoreMenuVisible] = useState(false);
  const { toggle, visible } = usePopup();
  // const [visible, setVisible] = useState(false);
  const [packages, setPackages] = useState([]);
  const [freePackages, setFreePackages] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [credits, setCredits] = useState([]);
  const [isKodeVisible, setIsKodeVisible] = useState(false);
  const [isAddProgramVisible, setIsAddProgramVisible] = useState(false);
  const [code, setCode] = useState("");
  const [loadProgram, setLoadProgram] = useState(false);
  const [token, setToken] = useState(undefined);
  const [userMode, setUserMode] = useState("member");
  const [contents, setContents] = useState([]);
  const [programs, setPrograms] = useState(null);
  const [recommendation, setRecommendation] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [tests, setTests] = useState([]);
  const [data, setData] = useState([]);
  const [materials, setMaterials] = useState([]);

  const navigation = useNavigation();
  const { t } = useTranslation();
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);

  useEffect(() => {
    AsyncStorage.setItem("dnes", "0");
    async function getToken() {

      const refId = await AsyncStorage.getItem("refId");
      if (refId) {
        await AsyncStorage.removeItem("token");
      }

      const token = await AsyncStorage.getItem("token");
      if (!token) {
        navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
        // return navigation.navigate("Login");
        return;
      }

      return token;
    }

    async function getMode() {
      let modes = {
        memberId: await AsyncStorage.getItem("token"),
        mode: await AsyncStorage.getItem("792f3e7"),
      };

      if (modes.mode === "speaker") {
        const speakerId = await AsyncStorage.getItem("792f3e1");
        modes["speakerId"] = speakerId;
      }

      setUserMode(modes);
      return userMode;
    }

    getMode();

    checkUpdate().then((res) => {
      if (APP_VERSION !== res?.data.v) {
        toggle();
      }
    });

    getPackages().then((res) => {
      const packs = res?.data;
      setPackages(packs);
      const freePacks = packs.filter((p) => p.productType === "12");
      setFreePackages(freePacks);
    });

    getToken().then((res) => {
      setToken(res);
      getTransactionByMember(res, "default").then((res) => {
        setTransactions(res?.data);
      });

      getMemberAgenda("member", res).then((res) => {
        setAgendas(res?.data);
      });

      getMemberCredits(res).then((res) => {
        setCredits(res?.data);
      });

      Promise.all([
        getProgram(13, res),
        getProgram(12, res),
        getProgram(18, res),
      ]).then((result) => {
        let datas = [];
        result.map((res) => {
          datas = [...datas, ...res.data];
        });
        setPrograms(datas);
      });

      getRecommendation(res).then((response) => {
        setRecommendation(response?.data);
      });

      getSurveys(APP_SPECIAL_PACKAGE, res, 1).then((res) => {
        setTests(res?.data);
      });

      setTimeout(() => {
        // resubscribe(res);
      }, 1000);
    });

    getContents().then((res) => {
      setContents(res?.data);
    });

    getTodayConsultants("expert").then((res) => {
      setData(res?.data);
    });

    getMaterials(APP_SPECIAL_PACKAGE).then((res) => {
      setMaterials(res?.data);
    });
  }, [loadProgram, route?.params?.newTime, refreshing]);

  useEffect(() => {
    if (!userMode?.memberId) {
      return;
    }

    if (Platform.OS !== "web") {
      messaging().onNotificationOpenedApp((remoteMessage) => {
        if (
          !remoteMessage?.data?.consId &&
          !remoteMessage?.data?.additionalContentId
        ) {
          return navigation.navigate("Chat");
        }

        if (remoteMessage?.data?.additionalContentId) {
          if (
            remoteMessage?.data?.additionalId &&
            remoteMessage?.data?.additionalId !== ""
          ) {
            getListDiscussionsById(
              remoteMessage?.data?.additionalContentId,
              userMode.memberId,
              remoteMessage?.data?.additionalId
            ).then((res) => {
              return navigation.navigate("DetailDiscussions", {
                additionalContentId: remoteMessage?.data?.additionalContentId,
                id: remoteMessage?.data?.additionalId,
                data: res?.data[0],
                alreadyLike: res?.data[0]?.totalView > 0,
              });
            });
          } else {
            getProgramId(remoteMessage?.data?.additionalContentId).then(
              (res) => {
                return navigation.navigate("ProgramDetail", {
                  program: res?.data[0],
                });
              }
            );
          }
        }

        if (remoteMessage?.data?.consId) {
          getSpesificAgenda(
            remoteMessage?.data?.consId,
            userMode.memberId
          ).then(async (res) => {
            const resp = res?.data;
            const clientChat = [];
            let speakerId = "";
            if (userMode.mode === "speaker") {
              speakerId = await AsyncStorage.getItem("792f3e1");
            }

            resp.map((c) => {
              const consultationTime = moment(
                c.consultationDate + " " + c.consultationHour.split("-")[0]
              );
              const consultationEnd = moment(
                c.consultationDate + " " + c.consultationHour.split("-")[1]
              );
              clientChat.push({
                title: c.summary,
                name:
                  c.memberId === userMode.memberId
                    ? c.speakerName
                    : c.memberName,
                time: consultationTime.format("DD MMM"),
                timeUnformatted: consultationEnd,
                isRead: true,
                isLike: true,
                mode: userMode.mode,
                target: c.speakerId,
                photo:
                  c.memberId === userMode.memberId
                    ? c.speakerImage
                    : c.memberPhoto,
                isDone: consultationEnd < moment(),
                consultationReady: consultationTime <= moment(),
                consId: c.consId,
                feedbackId: c.feedbackId,
                consultationDate: c.consultationDate,
                consultationHour: c.consultationHour,
                speakerId: c.speakerId,
                memberId: c.memberId,
                loggedInMemberId: userMode.memberId,
                speakerMemberId: c.speakerMemberId,
              });
            });
            return navigation.navigate("DetailChat", clientChat[0]);
          });
        }
      });
    }
  }, [userMode]);

  return (
    <>
      <StatusBar backgroundColor={"#6724E2"} />
      <ScrollView
        style={{ flex: 1, background: "#EEEAFF", paddingBottom: 20 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {/* <HomeHeader credits={credits} /> */}
        <HeaderJoin
          setLoadProgram={setLoadProgram}
          token={token}
          setShowSnackBar={setShowSnackBar}
          setSnackBarMsg={setSnackBarMsg}
        />
        {transactions?.length > 0 &&
          (transactions?.[0]?.theNumber === "1" ||
            !transactions?.[0]?.timeLimit) && (
            <View style={{ marginBottom: 24 }}>
              <SectionContent
                style={{ paddingHorizontal: 0 }}
                headerStyle={{ paddingHorizontal: 16 }}
                title={t("invoice-card:title")}
                hideAllButton
              >
                <View style={{ paddingHorizontal: 16 }}>
                  <InvoiceCard
                    data={transactions[0]}
                    t={t}
                    setShowSnackBar={setShowSnackBar}
                    setSnackBarMsg={setSnackBarMsg}
                  />
                </View>
              </SectionContent>
            </View>
          )}

        {agendas?.length > 0 && (
          <View style={{ marginTop: 24 }}>
            <SectionContent
              style={{ paddingHorizontal: 0 }}
              headerStyle={{ paddingHorizontal: 16 }}
              title={t("agenda-card:title")}
              hideAllButton
            >
              <View style={{ paddingHorizontal: 16 }}>
                <AgendaCard
                  data={agendas[0]}
                  userMode={userMode}
                  token={token}
                  t={t}
                />
              </View>
            </SectionContent>
          </View>
        )}

        {/* <HomeMenu onMore={() => setIsMoreMenuVisible(true)} t={t} /> */}

        {/* <JoinProgramCard
          onPressInput={() => {
            setCode("");
            setIsAddProgramVisible(true);
          }}
        /> */}
        {/* <View style={{ marginBottom: 24 }}>
           <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title="Video Plihan">
            <ScrollView
              horizontal={true}
              snapToInterval={Dimensions.get("window").width}
              showsHorizontalScrollIndicator={false}
            >
              {[0, 1, 2, 3]?.map((p, index) => (
                <CardVideo key={p} />
              ))}
            </ScrollView>
          </SectionContent>
        </View> */}
        {/* <JoinProgramCard onPressInput={() => {setCode("");setIsAddProgramVisible(true);}} /> */}
        {/* <View style={{ marginBottom: 24 }}>
             <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title="Kenali Masalah dengan Tes Psikologi" >
              <ScrollView
                horizontal={true}
style={{paddingHorizontal: 16}}
                snapToInterval={Dimensions.get("window").width}
                showsHorizontalScrollIndicator={false}
              >
                {[0,1,2,3]?.map((p, index) => (
                  <CardTest key={p} />
                ))}
              </ScrollView>
            </SectionContent>
          </View> */}

        {programs?.length > 0 && (
          <View style={{ marginTop: 24 }}>
            <SectionContent
              style={{ paddingHorizontal: 0 }}
              headerStyle={{ paddingHorizontal: 16 }}
              title="Program Kamu"
              handleAllButton={() => {
                navigation.navigate("Program");
              }}
            >
              <ScrollView
                horizontal={true}
                style={{ paddingHorizontal: 16}}
                showsHorizontalScrollIndicator={false}
              >
                {programs?.map((p, index) => (
                  <ChoosenProgram
                    titleCta={"Selengkapnya"}
                    key={p.productId}
                    data={p}
                    makeFull={programs?.length === 1}
                  />
                ))}
                <View style={{ width: 16 }} />
              </ScrollView>
            </SectionContent>
          </View>
        )}
        {tests?.length > 0 && (
          <View style={{ marginTop: 24 }}>
            <SectionContent
              style={{ paddingHorizontal: 0 }}
              headerStyle={{ paddingHorizontal: 16 }}
              title="Kenali Masalah dengan Tes Psikologi"
              handleAllButton={() =>
                navigation.navigate("PsikologiTestPage", {
                  resultOnly: "false",
                })
              }
            >
              <ScrollView
                horizontal={true}
                style={{ paddingHorizontal: 16 }}
                // snapToInterval={Dimensions.get("window").width}
                showsHorizontalScrollIndicator={false}
              >
                {tests?.map((p, index) => (
                  <CardTest key={index} data={p} />
                ))}
                <View style={{ width: 16 }} />
              </ScrollView>
            </SectionContent>
          </View>
        )}
        {recommendation?.length > 0 && (
          <View style={{ marginTop: 24 }}>
            <SectionContent
              style={{ paddingHorizontal: 0 }}
              headerStyle={{ paddingHorizontal: 16 }}
              title="Program Pilihan"
              handleAllButton={() => {
                navigation.navigate("Program", {
                  "selective": true
                });
              }}
            >
              <ScrollView
                horizontal={true}
                style={{ paddingHorizontal: 16 }}
                // snapToInterval={Dimensions.get("window").width}
                showsHorizontalScrollIndicator={false}
              >
                {recommendation?.map((p, index) => (
                  <ChoosenProgram
                    key={p.productId}
                    data={p}
                    setIsAddProgramVisible={setIsAddProgramVisible}
                    isRecommendation={"true"}
                    setLoadProgram={setLoadProgram}
                    setShowSnackBar={setShowSnackBar}
                    setSnackBarMsg={setSnackBarMsg}
                    token={token}
                    titleCta={""}
                    makeFull={recommendation?.length === 1}
                  />
                ))}
                <View style={{ width: 16 }} />
              </ScrollView>
            </SectionContent>
          </View>
        )}
        <View style={{ marginTop: 24 }}>
          <MenuCard />
        </View>
        {materials?.length > 0 && (
          <View style={{ marginTop: 24, paddingHorizontal: 0 }}>
            <EbookCard materials={materials} token={token} />
          </View>
        )}
        {data?.length > 0 && (
          <View style={{ marginTop: 24, marginBottom: 24 }}>
            <SectionContent
              style={{ paddingHorizontal: 0 }}
              headerStyle={{ paddingHorizontal: 16 }}
              title="Ahli Praktek Hari Ini"
              handleAllButton={() =>
                navigation.navigate("ChooseExpert", {
                  type: "expert",
                  today: "true",
                  status: "general",
                })
              }
            >
              <View style={{ paddingHorizontal: 16 }}>
                <TodayExpertCard data={data} />
              </View>
            </SectionContent>
          </View>
        )}
        {/* <View style={{ marginBottom: 24 }}>
           <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title="Program Pilihan">
            <ScrollView
              horizontal={true}
              snapToInterval={Dimensions.get("window").width}
              showsHorizontalScrollIndicator={false}
            >
              {[1, 2, 3, 4].map((p) => (
                <ChoosenProgram key={p} t={t} />
              ))}
            </ScrollView>
          </SectionContent>
        </View> */}

        {/* <View style={{ marginBottom: 24 }}>
           <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title={t("common:packages")} hideAllButton={true}>
            <ScrollView
              horizontal={true}
              snapToInterval={Dimensions.get("window").width}
              showsHorizontalScrollIndicator={false}
            >
              {packages?.map((p, index) => {
                if (p.productType !== "8") {
                  return <></>;
                }
                return <ServiceCard key={index} data={p} t={t} />;
              })}
            </ScrollView>
          </SectionContent>
        </View> */}

        {/* {freePackages.length > 0 && (
          <View style={{ marginBottom: 24 }}>
             <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title={t("promo-card:title")} hideAllButton>
              {freePackages.map(
                (f, index) =>
                  index < 1 && (
                    <PromoCard
                      data={f}
                      key={index}
                      setIsKodeVisible={setIsKodeVisible}
                      t={t}
                    />
                  )
              )}
            </SectionContent>
          </View>
        )} */}

        {/* {contents.length > 0 && (
          <View style={{ marginBottom: 24 }}>
             <SectionContent style={{paddingHorizontal: 0}} headerStyle={{paddingHorizontal: 16}} title={t("common:latest")} hideAllButton={true}>
              <ScrollView
                horizontal={true}
style={{paddingHorizontal: 16}}
                snapToInterval={Dimensions.get("window").width}
                showsHorizontalScrollIndicator={false}
              >
                {contents.map((c, index) => (
                  <Pressable
                    key={index}
                    onPress={() => {
                      Linking.openURL(c?.cta);
                    }}
                  >
                    <Image
                      source={{
                        uri: `https://fammi.ly/uploads/contents/images/${c?.image}`,
                      }}
                      style={{
                        width: Dimensions.get("window").width - 100,
                        maxWidth: 300,
                        height: 100,
                        marginRight: 12,
                        borderRadius: 10,
                      }}
                    />
                    <Image
                      source={{
                        uri: `https://fammi.ly/uploads/contents/images/${c?.image}`,
                      }}
                      style={{
                        width: Dimensions.get("window").width - 100,
                        maxWidth: 300,
                        height: 100,
                        marginRight: 12,
                        borderRadius: 10,
                      }}
                    />
                  </Pressable>
                ))}
              </ScrollView>
            </SectionContent>
          </View>
        )} */}

        <MoreMenuModal
          isVisible={isMoreMenuVisible}
          t={t}
          onClose={() => setIsMoreMenuVisible(false)}
        />
        <ModalKode
          visible={isKodeVisible}
          setIsKodeVisible={setIsKodeVisible}
          onClose={() => setIsKodeVisible(false)}
          setLoadProgram={setLoadProgram}
          code={code}
          token={token}
          setCode={setCode}
        />
        <ModalAddProgram
          t={t}
          token={token}
          code={code}
          setCode={setCode}
          onAddProgram={() => {
            setSnackBarMsg("Program berhasil ditambahkan");
            setShowSnackBar(true);
          }}
          setLoadProgram={setLoadProgram}
          visible={isAddProgramVisible}
          hideDialog={() => {
            setIsAddProgramVisible(false);
          }}
        />
      </ScrollView>
      <BottomNavigation activeIndex={0} />
      {Platform.OS !== "web" && (
        <PopupFactory
          type={"updates"}
          visible={visible}
          onCancel={toggle}
          onSubmit={() => {
            toggle();
            Linking.openURL("market://details?id=ly.fammi.kota");
          }}
        />
      )}
      <Snackbar
        visible={showSnackBar}
        hideDialog={() => {
          setShowSnackBar(false);
        }}
        title={snackBarMsg}
      />
    </>
  );
}

export default Home;
